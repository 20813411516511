import * as React from "react";
export const StoreContext = React.createContext(null);
import Cookies from "universal-cookie";
export const StoreProvider = ({ children }) => {
  const [cartItems, setCartItems] = React.useState(0);

  const [validations, setValidations] = React.useState({
    isLogged: false,
    isYardOwner: false,
  });
  const [width, setWidth] = React.useState(window.innerWidth);

  function OpenScanQr() {
    const openQr = new CustomEvent("openScanQr", { detail: { open: true } });
    window.dispatchEvent(openQr);
  }

  function handlepushState(newUrl) {
    const baseUrl = window.location.origin;
    const fullUrl = baseUrl + "/" + newUrl;
    window.history.pushState({}, null, fullUrl);
  }

  function handleOpenSidebar() {
    const sidebarEvent = new CustomEvent("openSideBarPhone", {
      detail: { open: true },
    });
    window.dispatchEvent(sidebarEvent);
  }

  function handleCheckValidations(user) {
    handleCheckIfIsSupra(user);
  }

  function handleIsNotLogged() {
    setValidations({
      isLogged: false,
      isYardOwner: false,
    });
  }

  function handleCheckIfIsSupra(user) {
    if (user?.type === "YardOwner") {
      setValidations({
        isLogged: true,
        isYardOwner: true,
      });
    } else {
      setValidations({
        isLogged: true,
        isYardOwner: false,
      });
    }
  }

  function handleCheckIfIsLogged() {
    const cookieCosmos = cookies.get("sessionCosmos");
    if (
      cookieCosmos !== undefined &&
      cookieCosmos !== "undefined" &&
      ((cookies.get("sessionCosmos").type === "YardOwner" &&
        cookies.get("sessionCosmos").YardOwnerStatus === "Enabled") ||
        cookies.get("sessionCosmos").type !== "YardOwner")
    ) {
      handleCheckValidations(cookies.get("sessionCosmos"));
    } else {
      window.addEventListener("UserDataLoaded", (e) => {
        handleCheckIfIsLogged();
      });
      handleIsNotLogged();
    }
  }

  const handlerSetFalse = () => {
    setValidations({
      isLogged: false,
      isYardOwner: false,
    });
  };

  function handleCalculateBadgeCount() {
    setCartItems(0);
    const yardProducts = localStorage.getItem("yardProducts");
    if (yardProducts === null) {
      return;
    }
    const products = JSON.parse(yardProducts);
    const count = products.length;
    setCartItems(count);
  }

  React.useEffect(() => {
    window.addEventListener("NotUser", handlerSetFalse);
  });

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    handleCheckIfIsLogged();
    handleCalculateBadgeCount();
    window.addEventListener("popstate", (e) => {
      handleCalculateBadgeCount();
      handleCheckIfIsLogged();
    });

    return () => {
      window.removeEventListener("popstate", handleCheckIfIsLogged());
    };
  }, []);
  window.addEventListener("itemOfProductsRemoved", (e) => {
    handleCalculateBadgeCount();
  });
  const cookies = new Cookies(null, { path: "/" });

  return (
    <StoreContext.Provider
      value={{
        handleOpenSidebar,
        OpenScanQr,
        validations,
        width,
        handlepushState,
        cartItems,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
