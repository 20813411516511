import * as React from "react";

const NavBarContext = React.createContext();

export const useNavBar = () => React.useContext(NavBarContext);

export const NavBarProvider = ({ children }) => {
  const [showNavBar, setShowNavBar] = React.useState(false);
  const [cartItems, setCartItems] = React.useState(0);
  function handleValidateCreateUser() {
    const url = window.location.href;
    if (url.includes("create-user") || url.includes("create-yard-owner")) {
      setShowNavBar(false);
    } else setShowNavBar(true);
  }

  function handleConfigurateNavBarClassName() {
    const route = document.querySelector(".root-container-yms");
    if (
      route &&
      !window.location.href.includes("create-user") &&
      route &&
      !window.location.href.includes("create-yard-owner")
    ) {
      route.classList.add("root-container-yms-activated");
    } else if (route) route.classList.remove("root-container-yms-activated");
  }

  React.useEffect(() => {
    window.addEventListener("popstate", (e) => {
      handleValidateCreateUser();
      handleConfigurateNavBarClassName();
    });
    handleValidateCreateUser();
    handleConfigurateNavBarClassName();
  }, []);

  return (
    <NavBarContext.Provider value={{ showNavBar }}>
      {children}
    </NavBarContext.Provider>
  );
};
