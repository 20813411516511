import * as React from "react";
import "./App.css";
import Bar from "./components/bar/bar";
import { StoreProvider } from "./store/storeContext";
import { NavBarProvider, useNavBar } from "./store/NabBarContext";

export default function App() {
  return (
    <NavBarProvider>
      <Root />
    </NavBarProvider>
  );
}

function Root(props) {
  const { showNavBar } = useNavBar();
  return (
    <StoreProvider>
      {showNavBar ? (
        <section className="navbar-container">
          <Bar />
        </section>
      ) : null}
    </StoreProvider>
  );
}
